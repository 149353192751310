<header id="main-header">
    <div class="container">
        <div class="row">
            
            <div class="col-lg-3 col-7 my-auto">
                <a href="{{Urlsitio}}" >
                    <img src="/assets/img/sorteo2024/logo2024_vf.png" class="img-fluid" alt="Sorteo Anáhuac 2024">
                </a>
            </div>

            <div class="col-lg-9 col-5 my-auto ">
                

                <div class="mobile-nav-trigger">
                    <div class="hamburger hamburger--squeeze">
                        <div class="hamburger-box">
                            <div class="hamburger-inner"></div>
                        </div>
                    </div>
                </div>
                <nav class="main-nav d-lg-block d-none">
                    <ul class="site-navigation "> 
                        <!-- <li><a href="{{Urlsitio}}">Inicio</a></li> -->
                        <li><a href="{{Urlsitio}}ganadores-sorteo-magno-novena-edicion" target="_blank" >Lista de ganadores</a></li>
                        <li><a href="{{Urlsitio}}login-ganadores" class="last-child latido-egr" target="_blank" style="background-color: #ff5900 ; color: #fff !important;">Recoge tu premio</a></li>
                        <!-- <li><a href="{{Urlsitio}}premios-sorteo-anahuac">Premios</a></li>
                       
                        <li><a href="{{Urlsitio}}formulario-registro-colaboradores"> Colabora</a></li>
                        -->
                       
                        <!-- <li class="drop">
                            <div class="dropdown show">
                                <a class=" dropdown-toggle" href="" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Sorteos
                                </a>
                              
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                   <div class="container-btn btn1 text-center">
                                        <a class="dropdown-item d-inline" href="{{Urlsitio}}sorteo-egresado-universidad-anahuac">
                                            <div class="text-center">
                                                <span>
                                                    SORTEO DE EGRESADOS
                                                </span>
                                            </div>
                                            <div class="number-count">
                                                <span>{{ dias_egr }}</span>.<span>{{ horas_egr }}</span>.<span>{{ minutos_egr }}</span>:<span>{{ segundos_egr }}</span>
                                            </div>
                                            <div class="labels text-center">
                                                <span>Días </span>
                                                <span>Hrs </span>
                                                <span>Min </span>
                                                <span>Seg </span>
                                            </div>
                                        </a>
                                       
                                    </div>

                                  <div class="container-btn btn2 text-center">
                                        <a class="dropdown-item d-inline" href="{{Urlsitio}}sorteo-oportuno">
                                            <div class="text-center">
                                                <span>
                                                    SORTEOS OPORTUNOS
                                                </span>
                                            </div>
                                            <div class="number-count">
                                                <span>{{ dias }}</span>.<span>{{ horas }}</span>.<span>{{ minutos }}</span>.<span>{{ segundos }}</span>
                                            </div>
                                            <div class="labels text-center">
                                                <span>Días </span>
                                                <span>Hrs </span>
                                                <span>Min </span>
                                                <span>Seg </span>
                                            </div>
                                        </a>
                                    </div> 
                                    <div class="container-btn btn3 text-center">
                                        <a class="dropdown-item d-inline" href="" target="_blank">
                                            <div class="text-center">
                                                <span>
                                                    SORTEO MAGNO
                                                </span>
                                            </div>
                                             <div class="number-count">
                                                <span>{{ day }}</span>.<span>{{ hours }}</span>.<span>{{ minutes }}</span>.<span>{{ seconds }}</span>
                                            </div>
                                            <div class="labels text-center">
                                                <span>Días </span>
                                                <span>Hrs </span>
                                                <span>Min </span>
                                                <span>Seg </span>
                                            </div>
                                        </a>
                                    </div>
                                
                                </div>
                              </div>
                        </li>  -->
<!--  -->
                        <!-- <li>
                            <a class="" href="{{Urlsitio}}sorteo-egresado-universidad-anahuac">
                                Egresados
                            </a>
                        </li> -->

                        <!-- <li>
                            <a class="" href="{{Urlsitio}}contacto">
                                Contacto
                            </a>
                        </li> -->
                        <!-- <li><a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/pagar-boletos-en-linea']" class="last-child latido-egr">Tus boletos</a></li> -->
                    </ul>
                </nav>

               
            </div>


        </div>
    </div>

   
</header>


<nav class="hidden-menu mobile-menu">
    <ul class="mobile-nav">
        <span class="close d-block d-md-none" (click)="close();">×</span>
        <h3 class="p-0 m-0">
            MENÚ
        </h3>
        <hr class="line p-0 ">

        <li><a href="{{Urlsitio}}ganadores-sorteo-magno-novena-edicion" class="last-child latido-egr main-nav-link" target="_blank" style="background-color: #ff5900 ; color: #fff !important;">Lista de ganadores</a></li>
        <li><a href="{{Urlsitio}}login-ganadores" class="last-child latido-egr main-nav-link" target="_blank" style="background-color: #ff5900 ; color: #fff !important;">Recoge tu premio</a></li>
        <li><a href="https://gana.sorteoanahuac.mx/" class="main-nav-link"> Blog</a></li>
        <li><a href="{{Urlsitio}}contacto" class="main-nav-link"> Contacto</a></li>

        <li><a href="{{Urlsitio}}remanentes" class="main-nav-link" target="_blank" > Destino de fondos</a></li>
        <li><a href="{{Urlsitio}}galeria" class="main-nav-link" target="_blank" > Galería</a></li>
        <li><a href="{{Urlsitio}}nosotros" class="main-nav-link" target="_blank" > Nosotros</a></li>
        <li><a href="{{Urlsitio}}preguntas-frecuentes" class="main-nav-link" target="_blank" > Preguntas frecuentes</a></li>
        <li><a href="{{Urlsitio}}premios-sorteo-anahuac" class="main-nav-link" target="_blank" > Premios</a></li>
        <li><a href="{{Urlsitio}}premios-para-colaboradores" class="main-nav-link" target="_blank" > Premios colaborador</a></li>

        <!-- <li><a href="{{Urlsitio}}formulario-registro-colaboradores" class="main-nav-link"> Colabora</a></li>
        <li><a href="{{Urlsitio}}premios-sorteo-anahuac" class="main-nav-link">Premios</a></li>
        <li><a href="{{Urlsitio}}nosotros" class="main-nav-link">Nosotros</a></li>
        <li><a href="{{Urlsitio}}premios-para-colaboradores" class="main-nav-link">Premios colaborador</a></li>
        <li><a href="{{Urlsitio}}remanentes" class="main-nav-link">Destino de fondos</a></li> -->
        <!-- <li><a href="{{Urlsitio}}mecanica" class="main-nav-link">Mecánica del sorteo</a></li> -->
        <!-- <li><a href="{{Urlsitio}}bases-de-sorteos-oportunos" class="main-nav-link">Mecánica de sorteos oportunos</a></li> -->
        <!-- <li><a href="{{Urlsitio}}bases-del-sorteo" class="main-nav-link">Bases del Sorteo 8a. Edición 2023</a></li> -->
        <!-- <li><a href="{{Urlsitio}}venta-meses-sin-intereses" class="main-nav-link">Meses sin intereses</a></li>
        <li><a href="{{Urlsitio}}galeria" class="main-nav-link">Galería</a></li> 
        <li><a href="{{Urlsitio}}blog" class="main-nav-link">Blog</a></li>
        <li><a href="{{Urlsitio}}fechas-importantes" class="main-nav-link">Fechas importantes</a></li>
        <li><a href="{{Urlsitio}}preguntas-frecuentes" class="main-nav-link">Preguntas frecuentes</a></li>
        <li><a href="{{Urlsitio}}contacto" class="main-nav-link">Contacto</a></li>
        <li><a (click)="close();" class="last-child latido-egr mt.2" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/pagar-boletos-en-linea']" >Tus boletos</a></li> -->
        <!-- <li class="mt-3 d-lg-none d-block"> 
            <a class=" main-nav-link" href="{{Urlsitio}}//sorteo-egresado-universidad-anahuac']">
                Egresados
            </a>
        </li> -->
    </ul>
</nav>


<div class="overlay" (click)="close();">
    
</div>

