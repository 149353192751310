<section class="banner-home" *ngIf="!showZod && this.info_zod.length == 0" >
  <div class="container">
    <div class="row">

      <div
        class="col-lg-6 col-md-12 order-lg-1 order-2  d-flex justify-content-center flex-column text-center counter-slide my"
        data-aos="fade-left" data-aos-easing="ease" data-aos-duration="3000">
        <img src="/assets/img/sorteo2024/left-agrada.png" class="img-fluid mx-auto ">

        <!-- <p style="margin-top: -35px;">
              ESTAMOS A 
          </p>
          <div class="counters d-flex justify-content-center">
              <div class="counter">
                  <div class="number">
                      <span >{{ day }}</span>
                  </div>
                  <p class="mb-0">Días</p>
              </div>
              <div class="space my-auto">
                  <span>:</span>
              </div>
              <div class="counter">
                  <div class="number">
                      <span >{{ hours }}</span>
                  </div>
                  <p class="mb-0">Horas</p>
              </div>
              <div class="space my-auto">
                  <span>:</span>
              </div>
              <div class="counter">
                  <div class="number">
                      <span >{{ minutes }}</span>
                  </div>
                  <p class="mb-0">Minutos</p>
              </div>
              <div class="space my-auto">
                  <span>:</span>
              </div>
              <div class="counter">
                  <div class="number">
                      <span >{{ seconds }}</span>
                  </div>
                  <p class="mb-0">Segundos</p>
              </div>
          </div>
          
          

          <p class="subtitle">
              DE SORPRENDER A <span>866</span> GANADORES
          </p>    -->


      </div>
      <div class="col-lg-6 col-md-12 my-auto order-md-2 order-1 mb-0">
        <div class="content-text">
          <h1>
            <span class="head-h1">
              ¡GRACIAS A TI
            </span>
           
            <span class="d-block">
              LOGRAMOS CUMPLIR SUEÑOS!
            </span>
          
          </h1>
       
          <p>
            ¡Gracias por sumarte a Sorteo Anáhuac y hacerlo posible!
            <span class="d-block">¡SÚMATE Y GANAEMOS TODOS!</span>
          </p>
        </div>
      </div>

      <!-- <div class="col-md-12 order-3 text-center">
        <p>
          ¡<span>SÚMATE</span> Y <span>GANEMOS</span> TODOS!
        </p>
      </div> -->


    </div>
  </div>
</section>


<section class="banner-home" *ngIf="!showZod && showDefault"   style="background-image: url({{info_zod[0].IMAGEN_FONDO}});" >
  <div class="container">
    <div class="row">

      <div
        class="col-lg-8 col-md-12 order-lg-1 order-2  d-flex justify-content-center flex-column text-center counter-slide my"
        data-aos="fade-left" data-aos-easing="ease" data-aos-duration="3000">
        <img src="{{ info_zod[0].IMAGEN_PRINCIPAL }}" class="img-fluid mx-auto ">

       
      </div>
      <div class="col-lg-4 col-md-12 my-auto order-md-2 order-1 mb-0">
        <div class="content-text">
          <h1>
            {{info_zod[0].DESCRIPCION}}
            <span class="d-block">
              {{info_zod[0].RECUADRO}}
            </span>
          </h1>
        </div>
      </div>

      <div class="col-md-12 order-3 text-center">
        <p>
          ¡<span>SÚMATE</span> Y <span>GANEMOS</span> TODOS!
        </p>
      </div>


    </div>
  </div>
</section>




<section class="banner-home" *ngIf="showZod" style="background-image: url({{info_zod[0].IMAGEN_FONDO}});" (click)="getBoletosZod()" data-toggle="modal"
data-target="#modalBoletos2">
  <div class="container">
    <div class="row">

      <div
        class="col-lg-8 col-md-12 order-lg-1 order-2  d-flex justify-content-center flex-column text-center counter-slide zod"
        data-aos="fade-left" data-aos-easing="ease" data-aos-duration="3000">
        <img src="{{ info_zod[0].IMAGEN_PRINCIPAL }}" class="img-fluid mx-auto ">
      </div>

      <div class="col-lg-4 col-md-12 my-auto order-md-2 order-1 mb-0 text-center zod" *ngIf=" showPkCompania == '001'">
        <div class="content-text">
          <h1>
            {{info_zod[0].DESCRIPCION}}
            <span class="d-block">
              {{info_zod[0].RECUADRO}}
            </span>
          </h1>
          <div *ngIf=" formattedNumbers.length === 2">
            <h2>{{ formattedNumbers[0] }} <span> y </span>{{ formattedNumbers[1] }}</h2>
          </div>
          <div *ngIf="formattedNumbers.length > 2">
            <h2>{{ formattedNumbers.join(', ') }}</h2>
          </div>

          <button  class="btn-stack" (click)="getBoletosZod()" >
            Buscar boleto
          </button>

        </div>
      </div>


      <div class="col-lg-4 col-md-12 my-auto order-md-2 order-1 mb-0 text-center zod" *ngIf=" showPkCompania != '001'">
        <div class="content-text">
          <h1>
            {{info_zod[0].DESCRIPCION}}
            <span class="d-block">
              {{info_zod[0].RECUADRO}}
            </span>
          </h1>

          <button id="btn-gm" class="btn-stack mt-4" (click)="getBoletosZod()">
            BOLETOS GRAN MES
          </button>

        </div>
      </div>


      <div class="col-md-12 order-3 text-center">
        <p>
          ¡<span>SÚMATE</span> Y <span>GANEMOS</span> TODOS!
        </p>
      </div>


    </div>
  </div>
</section>

<section id="lucky">
  <div class="container">
    <div class="row">

      <div class="col-lg-6 col-md-12">
        <h3>
          Máquina
          <span class="d-md-block">
            de la suerte
          </span>
        </h3>
        <p>
          ¡Pronto volverás a encontrar la suerte aquí!
        </p>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="content-machine">
          <div class="numbers btn-gradient">
            <ul id="list-n0">
              <li>0</li>
              <li>1</li>
              <li>2</li>
              <li>3</li>
              <li>4</li>
              <li>5</li>
              <li>6</li>
              <li>7</li>
              <li>8</li>
              <li>9</li>
              <li>0</li>
              <li>1</li>
              <li>2</li>
              <li>3</li>
              <li>4</li>
              <li>5</li>
              <li>6</li>
              <li>7</li>
              <li>8</li>
              <li>9</li>

            </ul>
          </div>
          <div class="numbers btn-gradient">
            <ul id="list-n1">
              <li>0</li>
              <li>1</li>
              <li>2</li>
              <li>3</li>
              <li>4</li>
              <li>5</li>
              <li>6</li>
              <li>7</li>
              <li>8</li>
              <li>9</li>
              <li>0</li>
              <li>1</li>
              <li>2</li>
              <li>3</li>
              <li>4</li>
              <li>5</li>
              <li>6</li>
              <li>7</li>
              <li>8</li>
              <li>9</li>


            </ul>
          </div>
          <div class="numbers btn-gradient">
            <ul id="list-n2">
              <li>0</li>
              <li>1</li>
              <li>2</li>
              <li>3</li>
              <li>4</li>
              <li>5</li>
              <li>6</li>
              <li>7</li>
              <li>8</li>
              <li>9</li>
              <li>0</li>
              <li>1</li>
              <li>2</li>
              <li>3</li>
              <li>4</li>
              <li>5</li>
              <li>6</li>
              <li>7</li>
              <li>8</li>
              <li>9</li>


            </ul>
          </div>

          <div class="numbers btn-gradient">
            <ul id="list-n3">
              <li>0</li>
              <li>1</li>
              <li>2</li>
              <li>3</li>
              <li>4</li>
              <li>5</li>
              <li>6</li>
              <li>7</li>
              <li>8</li>
              <li>9</li>
              <li>0</li>
              <li>1</li>
              <li>2</li>
              <li>3</li>
              <li>4</li>
              <li>5</li>
              <li>6</li>
              <li>7</li>
              <li>8</li>
              <li>9</li>


            </ul>
          </div>

          <div class="numbers btn-gradient">
            <ul id="list-n4">
              <li>0</li>
              <li>1</li>
              <li>2</li>
              <li>3</li>
              <li>4</li>
              <li>5</li>
              <li>6</li>
              <li>7</li>
              <li>8</li>
              <li>9</li>
              <li>0</li>
              <li>1</li>
              <li>2</li>
              <li>3</li>
              <li>4</li>
              <li>5</li>
              <li>6</li>
              <li>7</li>
              <li>8</li>
              <li>9</li>


            </ul>
          </div>

          <div class="numbers btn-gradient">
            <ul id="list-n5">
              <li>0</li>
              <li>1</li>
              <li>2</li>
              <li>3</li>
              <li>4</li>
              <li>5</li>
              <li>6</li>
              <li>7</li>
              <li>8</li>
              <li>9</li>
              <li>0</li>
              <li>1</li>
              <li>2</li>
              <li>3</li>
              <li>4</li>
              <li>5</li>
              <li>6</li>
              <li>7</li>
              <li>8</li>
              <li>9</li>


            </ul>
          </div>

        </div>

        <div class="buttons-generate text-center mt-3">
          <a *ngIf="!flag_buy" (click)="getBoletoRandom();" class="btn-stack generate-number  mx-auto">

            ¡Activa tu suerte
            aquí!
          </a>
          <!-- <a class="btn-stack generate-number  mx-auto">

            ¡Próximamente 2025!
          </a> -->
          
          <div *ngIf="flag_buy_loader" class="w-100">
            <p>
              Generando tu número...
            </p>
            <div class="lds-hourglass"></div>

          </div>

          <button *ngIf="flag_buy_btn" class="btn-stack btn-search mt-4" (click)="efectuarPago()"
            [disabled]="botonDisabled">
            Agregar a Carrito
          </button>

          <div id="Msg" [hidden]="true" class="alert alert-info mostrar text-center">
            Por el momento no contamos con boletos disponibles
          </div>
          <!-- Mensaje de error: Cuando no está disponible el boletos consultado -->
          <div *ngIf="boleto_disponible == 0" class="alert alert-info mostrar text-center">
            El boleto ya no se encuentra disponible, favor de escoger otro
          </div>
        </div>
      </div>

    </div>
  </div>
</section>

<section id="stc-inputs">
  <div class="container">
    <div class="row container-input">
      <div class="col-md-12">
        <h3 class="text-center">
          o selecciona tus boletos por:
        </h3>
      </div>

      <div class="col-md-12">
        <div class="row inside">
          <!-- input 1 -->
          <div class="col-md-4 offset-2">
            <label for="busq-three" class="">
              Boletos que inicien en:
              <input type="radio" id="busq-three" name="options" (click)="inputDisabled(3)"
                [checked]="!inputDisabled_3" />
            </label>
          </div>
          <div class="col-md-4">
            <input type="tel" class="input-box" placeholder=" _ _ " maxlength="2" [(ngModel)]="option_3"
              [disabled]="inputDisabled_3" />
          </div>

          <!-- input 2 -->
          <div class="col-md-4 offset-2">
            <label for="busq-one" class="">
              Boletos que terminen en:
              <input type="radio" id="busq-one" name="options" (click)="inputDisabled(1)"
                [checked]="!inputDisabled_1" />

            </label>
          </div>

          <div class="col-md-4">
            <input type="tel" class="input-box" placeholder=" _ _ " maxlength="2" [(ngModel)]="option_1"
              [disabled]="inputDisabled_1" />
          </div>

          <!-- input 3 -->
          <div class="col-md-4 offset-2">
            <label for="busq-two" class="">
              Boletos que contengan los dígitos:
              <input type="radio" id="busq-two" name="options" (click)="inputDisabled(2)"
                [checked]="!inputDisabled_2" />

            </label>
          </div>
          <div class="col-md-4">
            <input type="tel" class="input-box" placeholder=" _ _ " maxlength="2" [(ngModel)]="option_2"
              [disabled]="inputDisabled_2" />
          </div>

          <!-- input 4 -->

          <div class="col-md-4 offset-2">
            <label for="busq-four" class="">
              ¿Buscas tu número de la suerte?
              <span class="d-md-block">(teclea aquí)</span>
              <input type="radio" id="busq-four" name="options" (click)="inputDisabled(4)"
                [checked]="!inputDisabled_4" />

            </label>
          </div>
          <div class="col-md-4">
            <!-- Validar el minimo de caracteres -->
            <input type="tel" class="input-box" placeholder=" _ _ _ _ _ _ " maxlength="6" [(ngModel)]="option_4"
              [disabled]="inputDisabled_4" />
          </div>



        </div>
      </div>

      <div class="col-12 col-md-6 text-center"
        *ngIf="option_1 ? true : option_2 ? true : option_3 ? true : option_4 ? true : false">
        <button class="btn-action btn-stack btn-search" (click)="getLuckyBoleto(option_1, option_2, option_3, option_4)"
          data-toggle="modal" data-target="#modalBoletos">
          Buscar boleto
        </button>
      </div>

      <div class="col-12 col-md-6 text-center">
        <button class="btn-action btn-stack" (click)="efectuarPago()" [disabled]="botonDisabled">Agregar a
          Carrito</button>
      </div>

      <div class="col-12 col-md-6 text-center" *ngIf="boleto_.length > 0">
        <button id="item" class="btn-action btn-stack" (click)="efectuarPago()" visibility="hidden">Pagar</button>
      </div>




    </div>
  </div>
</section>


<!-- Modal zodiaco-->
<div class="modal fade" id="modalBoletos2" tabindex="-1" role="dialog" aria-labelledby="modalBoletosCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header"  *ngIf=" showPkCompania == '001' ">
        <h5>Boletos generados numeros {{ formattedNumbers.join(', ') }}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-header"  *ngIf=" showPkCompania != '001'">
        <h5>Boletos generados </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body text-center">
        <div class="row" id="ListBol2" >

          <div *ngFor="let boleto of boletos" class="col-lg-3 col-md-4 col-6">
            <label class="" *ngIf="boleto.folio">
              <input name="boletos-seleccionados" type="checkbox" [(ngModel)]="boleto.marcado"
                value="{{ boleto.folio }}" (change)="btnable(boleto.marcado)" />

              {{ boleto.folio }}
              <span class="checkmark chec"></span>
            </label>
          </div>
          

        </div>


      </div>
      <div class="modal-footer">
        <button type="button" class="btn-stack" data-dismiss="modal">
          Cerrar
        </button>
        <button class=" btn-stack" (click)="efectuarPago2()" [disabled]="botonDisabled"
          data-dismiss="modal">Agregar a Carrito</button>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="modalBoletos" tabindex="-1" role="dialog" aria-labelledby="modalBoletosCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5>Boletos generados</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        <div class="row" id="ListBol" [hidden]="true">

          <div [class.mostrar]="Error === true" [class.ocultar]="Error === false"
            class="text-danger col-12 text-center">
            <h4>
              {{ mensajeError }}
            </h4>
          </div>

          <div [class.mostrar]="ErrorSuerte === true" [class.ocultar]="ErrorSuerte === false"
            class="text-danger col-12 text-center">
            <h4>
              En este Sorteo el boleto no está disponible
              ¿deseas que te lo reservemos para el próximo Sorteo?
            </h4>
            <button class="btn-action btn-stack d-block border-0 text-center mx-auto mt-4"
              (click)="Registrobol(option_4)">
              Reservar boleto
            </button>
          </div>



          <div *ngFor="let boleto of boletos" class="col-lg-3 col-md-4 col-6">
            <label class="" *ngIf="boleto.folio">
              <input name="boletos-seleccionados" type="checkbox" [(ngModel)]="boleto.marcado"
                value="{{ boleto.folio }}" (change)="btnable(boleto.marcado)" />

              {{ boleto.folio }}
              <span class="checkmark chec"></span>
            </label>
          </div>

        </div>


      </div>
      <div class="modal-footer">
        <button type="button" class="btn-stack" data-dismiss="modal">
          Cerrar
        </button>
        <button class="btn-action btn-stack" (click)="efectuarPago()" [disabled]="botonDisabled"
          data-dismiss="modal">Agregar a Carrito</button>
      </div>
    </div>
  </div>
</div>



<!-- Boletos de la suerte pop -->
<div class="alert popamigable" role="alert" id="popupamigable" *ngIf="PopBoleto">
  <div class="container">

    <!-- <label class="poptext">Boleto de la Suerte</label>	<br>		 -->
    <label class="text-center w-100">Ingresa tus datos y registra tu Boleto(s) de la Suerte(s) para proximos sorteos
      ¡Participa!</label>

    <div class="row listluck center">
      <div *ngFor="let boleto of boletosLuckfil" style="margin: auto"
        class="col-xs-2 col-sm-4 col-md-4 col-lg-4 col-xl-4 center">
        <label class="label labellck label-info " *ngIf="boleto.folio" style="padding-left: 13px">
          <!--
                              (boleto_check = false) diferente de FALSE
                          -->
          <input name="boletos-seleccionados" type="checkbox" [(ngModel)]="boleto.marcado" value="{{ boleto.folio }}"
            (change)="btnable(boleto.marcado)" />

          {{ boleto.folio }}
          <span class="checkmark chec checl"></span>
        </label>

        <label class="label labellck label-info " *ngIf="boleto.estado_boleto == 'Null'" style="padding-left: 13px">
        </label>
      </div>
    </div>
    <div class="center">
      <button id="Mas" *ngIf="boletosLuckfil.length > 2 && boletosLuck.length > 3" type="button"
        style="place-content: center" class="btn btn-pop" (click)="GetLuckyPermBoleto()">
        Más
      </button>
    </div>

    <form class="form" [formGroup]="formDatosComprador">
      <div class="form-group" [ngClass]="{ 'has-error': formDatosComprador.controls.nombre.invalid && valida }">
        <div class="col-12 block">
          <label class="control-label" [ngClass]="{ validatexto: formDatosComprador.controls.nombre.invalid && valida }"
            for="nombre">Nombre(s)</label>
          <input type="text" [(ngModel)]="Info.nombre" class="form-control shadow" formControlName="nombre" />
        </div>
      </div>

      <div class="form-group" [ngClass]="{ 'has-error': formDatosComprador.controls.apellidos.invalid && valida }">
        <div class="col-12 block">
          <label class="control-label"
            [ngClass]="{ validatexto: formDatosComprador.controls.apellidos.invalid && valida }"
            for="apellidos">Apellidos</label>
          <input type="text" [(ngModel)]="Info.apellidos" class="form-control shadow" formControlName="apellidos" />
        </div>
      </div>

      <div>
        <div class="form-group" [ngClass]="{ 'has-error': formDatosComprador.controls.celular.invalid && valida }">
          <div class="col-12 block">
            <label class="control-label"
              [ngClass]="{ validatexto: formDatosComprador.controls.celular.invalid && valida }"
              for="celular">Teléfono</label>
            <input type="tel" [(ngModel)]="Info.celular" class="form-control shadow" maxlength="15"
              formControlName="celular" />
          </div>
        </div>
      </div>
      <div>
        <div class="form-group" [ngClass]="{ 'has-error': formDatosComprador.controls.correo.invalid && valida }">
          <div class="col-12 block">
            <label class="control-label"
              [ngClass]="{ validatexto: formDatosComprador.controls.correo.invalid && valida }" for="correo">Correo
              electrónico</label>
            <input type="email" [(ngModel)]="Info.correo" class="form-control shadow" maxlength="50"
              formControlName="correo" />
          </div>
        </div>
      </div>
    </form>
    <div class="modal-footer center">
      <button id="registrarBoleto" type="button" style="place-content: center" class="btn btn-pop btn-stack"
        (click)="RegistroDatos()">
        Registrar
      </button>
      <button id="cerrarpop" type="button" style="place-content: center" class="btn btn-pop btn-stack"
        (click)="CloseLuckyBoleto()">
        Cerrar
      </button>
    </div>
  </div>
</div>
<!-- Boletos de la suerte pop -->
